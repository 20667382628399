export const TOGGLE_DISABLE_BUTTON = "TOGGLE_DISABLE_BUTTON";
export const TOGGLE_MENU_BAR = "TOGGLE_MENU_BAR";
export const TOGGLE_DOMAIN_CONTROL_MENU = "TOGGLE_DOMAIN_CONTROL_MENU";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION";
export const SET_DOMAINS = "SET_DOMAINS";
export const SET_CURRENT_DOMAIN = "SET_CURRENT_DOMAIN";
export const SET_CREATE_DOMAIN_MODAL = "SET_CREATE_DOMAIN_MODAL";
export const SET_REMOVE_DOMAIN_MODAL = "SET_REMOVE_DOMAIN_MODAL";
export const SET_ALL_ACTIVITIES = "SET_ALL_ACTIVITIES";
export const SET_ACTIVITY = "SET_ACTIVITY";
export const SET_ACTIVITIES_SEARCH = "SET_ACTIVITIES_SEARCH";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const OPEN_KEYWORDS_CREATE_MODAL = "OPEN_KEYWORDS_CREATE_MODAL";
export const SET_KEYWORDS = "SET_KEYWORDS";
export const SET_KEYWORDS_SEARCH = "SET_KEYWORDS_SEARCH";
export const SET_KEYWORDS_SORT = "SET_KEYWORDS_SORT";
export const SET_KEYWORD = "SET_KEYWORD";
export const ADD_KEYWORD_TO_BE_REMOVED = "ADD_KEYWORD_TO_BE_REMOVED";
export const SET_RANK = "SET_RANK";
export const SET_PLANS = "SET_PLANS";
export const SET_ANNUAL_CHECK = "SET_ANNUAL_CHECK";
export const SET_ALL_REPORTS = "SET_ALL_REPORTS";
export const SET_NEW_REPORT = "SET_NEW_REPORT";
export const SET_REPORT_SEARCH = "SET_REPORT_SEARCH";
export const DELETE_REPORT = "DELETE_REPORT";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_NEW_USER = "SET_NEW_USER";
export const SET_USER_SEARCH = "SET_USER_SEARCH";
export const UPDATE_USER = "UPDATE_USER";
export const SET_PAGES = "SET_PAGES";
export const SET_PAGES_SORTER = "SET_PAGES_SORTER";
export const SET_BACKLINKS_DATA = " SET_BACKLINKS_DATA";
export const SHOW_BACKLINK_MODAL = "SHOW_BACKLINK_MODAL";
export const SET_CURRENT_BACKLINK = "SET_CURRENT_BACKLINK";
export const SET_BACKLINKS = "SET_BACKLINKS";
export const SET_DASHBOARD_LOADING = "SET_DASHBOARD_LOADING";
export const SET_FILTERS = "SET_FILTERS";
export const RESET_BACKLINKS = "RESET_BACKLINKS";
export const BACKLINKS_INFO = 'BACKLINKS_INFO'
export const VIEW_TYPE = 'VIEW_TYPE'
export const SET_TAGS = 'SET_TAGS'
